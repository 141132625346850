import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { checkRouteHasAccess, isPublicRoute } from '../../model/engage/user-detail';
import { UserDetailService } from '../../service/common/user/user-detail.service';
import { MenuList} from '../../app.constant';
import { ROUTES } from '../../app-routes.constant';

@Component({
  selector: 'dd-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  public homeCards = {
    firstRow: [{
      cardHeader: 'Initiate Review',
      imgSrc: 'assets/images/icon-start-review.svg',
      alt: 'Initiate Review',
      param: ROUTES.INITIATE_REVIEW
    }, {
      cardHeader: 'My Reviews',
      imgSrc: 'assets/images/icon-my-reviews.svg',
      alt: 'My Review',
      param: ROUTES.MY_REVIEW
    }, {
      cardHeader: 'Legal Guidelines',
      imgSrc: 'assets/images/icon-legal-guidelines.svg',
      alt: 'Legal Guidelines',
      param: ROUTES.REVIEW_GUIDELINES
    }],
    secondRow: [{
      cardHeader: 'Help',
      imgSrc: 'assets/images/icon-help.svg',
      alt: 'Help',
      param: ROUTES.HELP_CENTER
    }, {
      cardHeader: MenuList.searchReview,
      imgSrc: 'assets/images/icon-search.svg',
      alt: 'search review',
      param: ROUTES.SEARCH_REVIEW
    }, {
      cardHeader: 'Manage Review',
      imgSrc: 'assets/images/icon-manage-reviews.svg',
      alt: 'Manage Review',
      param: ROUTES.MANAGE_REVIEW
    }]
  };
  constructor(public router: Router,
    public userDetailService: UserDetailService) { }

  ngOnInit() {
    //empty method
  }

  navigateToMenu(menuItem) {
    if (!this.checkRouteAccess(menuItem)) {
      return
    }
    switch (menuItem) {
      case ROUTES.INITIATE_REVIEW:
        this.router.navigate([ROUTES.INITIATE_REVIEW]);
        break;
      case ROUTES.REVIEW_GUIDELINES:
        this.router.navigate([ROUTES.REVIEW_GUIDELINES]);
        break;
      case ROUTES.HELP_CENTER:
        this.router.navigate([ROUTES.HELP_CENTER]);
        break;
      case ROUTES.MY_REVIEW:
        this.router.navigate([ROUTES.MY_REVIEW]);
        break;
      case 'profile':
        this.router.navigate([ROUTES.MY_PROFILE]);
        break;
      case ROUTES.MANAGE_REVIEW:
        this.router.navigate([ROUTES.MANAGE_REVIEW]);
        break;
      default:
        this.router.navigate([menuItem]);
        break;
    }
  }

  checkRouteAccess(path) {
    if (isPublicRoute(path)) {
      return true
    }
    if (!this.userDetailService.isActiveUser()) {
      return false
    }
    const userRole = this.userDetailService.loggedInUserDetailData.groups;
    return checkRouteHasAccess(path, userRole);
  }

  handleKeyDown(event: KeyboardEvent, param: string): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault(); // Prevent default scrolling for Space
      this.navigateToMenu(param);
    }
  }

}
