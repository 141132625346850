import { Component, ElementRef, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TdrsHttpEnvironmentService } from '../../service/environment';
import { StaticContentService } from '../../service/features/static-content.service';
import { isValidURL,utilGet } from '../../service/library/library';
import { FILE_TYPES, WARNINGS } from '../../app.constant';
import { downloadFile } from '../../common/file-download';
import { PreviewPopupService } from '../../../app/feature/preview-popup/preview-popup.service';

@Component({
  selector: 'dd-helpcenter',
  templateUrl: './helpcenter.component.html',
  styleUrls: ['./helpcenter.component.scss']
})
export class HelpcenterComponent implements OnInit {
  public helpcentercontent;
  public helpData;
  public helpDataWarning;
  public showSpinner: boolean = false;
  imageDataUrl: any;
  mediaType: string;
  previewUrl: string;

  constructor(public sanitizer: DomSanitizer,
    public environmentService: TdrsHttpEnvironmentService,
    public elementRef: ElementRef, private previewPopupService: PreviewPopupService,
    private readonly staticContentService: StaticContentService) { }

  gethelpData() {
    this.showSpinner = true;
    this.staticContentService.getHelpCenterData().subscribe(res => {
      if (res.body.statusCode === 200) {
        this.showSpinner = false;
        this.helpcentercontent = utilGet(res, 'body.data', '');
        this.helpcentercontent = this.helpcentercontent.replace(/[/]ns+[/]+resources+[/]+Project-Review-User-Guide-8.23.20.pdf/g,
          'https://' + this.environmentService.api.url + '/api/v1/review/guidelines/FAQs');
        this.helpcentercontent = this.helpcentercontent.replace(/href1/g, 'data-apiurl');
        this.helpData = this.sanitizer.bypassSecurityTrustHtml(this.helpcentercontent);
        this.elementRef.nativeElement.addEventListener('click', (event: any) => {
          this.setDatatagName(event)
        }, false);
        this.elementRef.nativeElement.addEventListener('click', (event: any) => {
          this.setDatatagName(event)
        }, false);
      } else {
        this.displayWarning(res.body.message);
      }
    }, e => {
      this.displayWarning(WARNINGS.UnknownError);
    });
  }

  ngOnInit() {
    this.gethelpData();
  }

  downloadFromAPI(url) {
    if (isValidURL(url)) {
      const contentService = this.staticContentService;
      this.showSpinner = true;
      const updatedUrl = "HelpCenter/Project-Review-User-Guide-8.23.20.pdf"; // updated the URL
      contentService.downLoadFiles(updatedUrl).subscribe((res) => {
        downloadFile(res.body, 'user_guide.pdf', FILE_TYPES.PDF);
        this.showSpinner = false;
      }, () => {
        this.showSpinner = false;
      });
    }
  }

  setDatatagName(event) {
    let index;
    const eventPath = event?.path || event?.composedPath();
    if (event.srcElement?.alt == 'User Guide Cover' || event.srcElement?.alt == undefined) {
      if (eventPath.length > 0) {
        index = eventPath?.findIndex((element) => element.nodeName === 'A');
        let dataTagName;
        if (index > -1 && eventPath[index].dataset && eventPath[index].dataset.apiurl) {
          dataTagName = eventPath[index].dataset.apiurl;
        }
        this.downloadFromAPI(dataTagName);
      }
    }
    else if (event.srcElement.alt == 'Review Leader Instructions') {
      this.imageDataUrl = "/assets/images/static-content/ns/resources/video-poster1.png"
      this.mediaType = "video"
      this.previewUrl = "assets/images/static-content/ns/resources/Review-Leader-Training-Video-10.14.22.mp4"
      this.previewPopupService.open(this.imageDataUrl,this.mediaType, this.previewUrl);
    }
    else if (event.srcElement.alt == 'Agency Instructions') {
      this.imageDataUrl = "/assets/images/static-content/ns/resources/video-poster2.png"
      this.mediaType = "video"
      this.previewUrl = "assets/images/static-content/ns/resources/Agency-Training-Video-7.18.22.mp4"
      this.previewPopupService.open(this.imageDataUrl,this.mediaType, this.previewUrl);
    }
    else if (event.srcElement.alt == 'How to Submit Videos') {
      this.imageDataUrl = "/assets/images/static-content/ns/resources/video-poster3.png"
      this.mediaType = "video"
      this.previewUrl = "assets/images/static-content/ns/resources/How-to-Submit-Videos-7.18-22.mp4"
      this.previewPopupService.open(this.imageDataUrl,this.mediaType, this.previewUrl);
    }
  }

  displayWarning(errorMessge) {
    this.showSpinner = false;
    this.helpDataWarning = errorMessge;
  }

  handleKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault(); // Prevent the default scrolling for Space
      window.print();
    }
  }
}
